import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { FeatureFlagsProvider } from "./hooks/useFeatureFlags";
import { useRoleAuth } from "./hooks/useRoleAuth";
import { Agent } from "./pages/Agent";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { Settings } from "./pages/Settings";
import { myTheme } from "./theme";
import { loadToken } from "./utils/validateToken";

// Segment tracking
declare global {
  interface Window {
    analytics: any;
  }
}

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: Array<"USER" | "ADMIN" | "SUPER_ADMIN">;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles = [],
}) => {
  const token = loadToken();
  const { hasPermission } = useRoleAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles.length > 0 && !hasPermission(allowedRoles)) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <ChakraProvider theme={myTheme}>
      <FeatureFlagsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agents/:id/:mode?/:assistant_id?"
              element={
                <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                  <Agent />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </FeatureFlagsProvider>
    </ChakraProvider>
  );
}

export default App;
