import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Agent as AgentType } from "../client/types";
import { AgentHome } from "../components/Agent/AgentHome";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";

export const Agent = () => {
  const { id, assistant_id } = useParams<{
    id: string;
    assistant_id: string;
  }>();
  const { agents, loading } = useListAgents();
  const [selectedAgent, setSelectedAgent] = useState<AgentType | null>(null);

  // Set initial selected agent based on URL param
  useEffect(() => {
    if (agents && id) {
      const agent = agents.find(a => a.id === id);
      if (agent) {
        setSelectedAgent(agent);
      }
    }
  }, [agents, id]);

  const handleAgentSelect = (agent: AgentType) => {
    setSelectedAgent(agent);
  };

  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar 
          agent_version={assistant_id ?? ""} 
          agents={agents} 
          agentsLoading={loading}
          onAgentSelect={handleAgentSelect}
        />
      </Flex>
      <Flex flex="4" justify="left" maxW="100vw">
        <AgentHome selectedAgent={selectedAgent} />
      </Flex>
    </Flex>
  );
};
