import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  Stack,
  Spinner,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { FaRobot } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Agent } from "../../client/types";
import { SidebarButton } from "../Sidebar/SidebarButton";
import { useRoleAuth } from "../../hooks/useRoleAuth";
export const AgentsCollapse = ({
  agents,
  isCollapsed,
  loading,
  onAgentSelect
}: {
    isCollapsed?: boolean;
    agents: Agent[];
    loading: boolean;
    onAgentSelect?: (agent: Agent) => void;

  }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { hasPermission } = useRoleAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAgentClick = (agent: Agent) => {
    navigate(`/agents/${agent.id}`);
    onAgentSelect?.(agent);
  };

  if (isCollapsed) {
    return (
      <SidebarButton
        leftIcon={<FaRobot />}
        isActive={isOpen}
        onClick={() => navigate("/home")}
        isCollapsed={isCollapsed}
      >
        My Agents
      </SidebarButton>
    );
  }

  if (hasPermission(['ADMIN', 'SUPER_ADMIN'])) {
    return (
      <Box>
        <Button
          variant="tertiary"
          onClick={() => navigate("/home")}
          width="full"
          justifyContent="space-between"
          pl="3"
        >
          <HStack spacing="3">
            <Icon as={FaRobot} />
            <Text as="span">My Agents</Text>
            {loading && (
              <Box display="flex" justifyContent="center" alignItems="center" py="4" color="purple.500">
                <Spinner size="xs" />
              </Box>
            )}
          </HStack>
          <Box onClick={(e) => { e.stopPropagation(); onToggle(); }}>
            <PopoverIcon isOpen={isOpen} />
          </Box>
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Stack
            spacing="1"
            alignItems="stretch"
            ps="8"
            py="1"
            overflowY="auto"
          >
            {agents.map((agent: Agent) => (
              <Button
                key={agent.id}
                variant="tertiary"
                width="full"
                justifyContent="start"
                onClick={() => handleAgentClick(agent)}
                isActive={location.pathname.toLowerCase() === `/agents/${agent.id}`}
              >
                {agent.name}
              </Button>
            ))}


          </Stack>

        </Collapse>
      </Box>
    );
  }

  // For non-admin users, show just a button that navigates to home
  return (
    <Button
      variant="tertiary"
      onClick={() => navigate("/home")}
      justifyContent="start"
      width="full"
    >
      <HStack spacing="3">
        <Icon as={FaRobot} />
        <Text as="span">My Agents</Text>
      </HStack>
    </Button>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
