import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoAnalytics } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { Logo } from "../Common/Logo";
import { QuickMenu } from "./QuickMenu";
import { SidebarButton } from "./SidebarButton";
import { AgentsCollapse } from "../MyAgents/AgentsCollapse";
import { Agent } from "../../client/types";

export const SideBar = ({ 
  agents, 
  agent_version, 
  agentsLoading,
  onAgentSelect 
}: { 
  agents: Agent[]; 
  agent_version?: string;
  agentsLoading?: boolean;
  onAgentSelect?: (agent: Agent) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = localStorage.getItem("email") || "";
  const full_name = localStorage.getItem("full_name") || "";
  const { flags } = useFeatureFlags();
  const { hasPermission } = useRoleAuth();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const storedIsCollapsed = localStorage.getItem("sidebarCollapsed");
    return storedIsCollapsed ? JSON.parse(storedIsCollapsed) : false;
  });

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  const handleNavClick = (path: string) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Flex as="section" maxH="100vh">
      <Stack
        flex="1"
        width={isCollapsed ? "60px" : { base: "full", sm: "xs", md: "sm" }}
        py={{ base: "4", sm: "6", md: "8" }}
        px={isCollapsed ? "2" : { base: "2", sm: "4", md: "6" }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
        position="relative"
        transition="width 0.2s"
      >
        <Stack spacing="6" overflowY="auto" flex="1">
            <Box alignSelf="center" overflow="visible">
            <Logo
              minW={isCollapsed ? "6vh" : { base: "12vh", sm: "11.5vh" }}
              minH={isCollapsed ? "6vh" : { base: "12vh", sm: "11.5vh" }}
              transition="all 0.2s"
              opacity={isCollapsed ? 1 : 1}
            />
            </Box>
          <Stack spacing="1">
            {flags?.showDashboard && hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
              <SidebarButton
          leftIcon={<IoAnalytics />}
          isActive={location.pathname.toLowerCase() === "/dashboard"}
          onClick={() => handleNavClick("/dashboard")}
          isCollapsed={isCollapsed}
              >
          Dashboard
              </SidebarButton>
            )}
            <AgentsCollapse 
              isCollapsed={isCollapsed} 
              agents={agents} 
              loading={agentsLoading || false}
              onAgentSelect={onAgentSelect}
            />
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          <Box />
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              {isCollapsed ? (
                <QuickMenu />
              ) : (
                <Avatar
                  size="sm"
                  name={full_name}
                  src=""
                  bg="blue.500"
                  color="white"
                  fontSize="sm"
                  fontWeight="bold"
                />
              )}
              <Box
                opacity={isCollapsed ? 0 : 1}
                transition="opacity 0.2s"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                <Text fontSize={{ base: "sm", md: "md" }} fontWeight="medium">
                  {full_name}
                </Text>
                <Text fontSize={{ base: "xs", md: "sm" }} color="fg.muted">
                  {email}
                </Text>
              </Box>
            </HStack>
            <Box opacity={isCollapsed ? 0 : 1} transition="opacity 0.2s">
              <QuickMenu />
            </Box>
          </HStack>
        </Stack>
        <IconButton
          aria-label="Toggle Sidebar"
          icon={
            isCollapsed ? (
              <ArrowRightIcon boxSize={3} />
            ) : (
              <ArrowLeftIcon boxSize={3} />
            )
          }
          onClick={toggleSidebar}
          position="absolute"
          right={{ base: "-8px", md: "-10px" }}
          top="50%"
          transform="translateY(-50%)"
          bg="white"
          color="gray.500"
          _hover={{ bg: "gray.100" }}
          boxShadow="sm"
          size="sm"
          borderRadius="full"
        />
      </Stack>
    </Flex>
  );
};
