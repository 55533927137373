import { EditIcon, LinkIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Center,
  HStack,
  IconButton,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useDeleteAgentVersion } from "../../hooks/useDeleteAgentVersion";
import { BOT_API_URL } from "../../utils/constants";
import { loadToken } from "../../utils/validateToken";
import { VersionConfig } from "./VersionConfig";

export const AgentVersionsTable = (
  {
    id,
    isEditing,
    setIsEditing,
    selectedVersion, // Receive selectedVersion as a prop
    setSelectedVersion, // Receive setSelectedVersion as a prop
    agentVersions,
    onUpdatedAgentVersion,
    agentName
  }: {
    id: string;
    isEditing: boolean;
    setIsEditing: (editing: boolean) => void;
    selectedVersion: string | null;
    setSelectedVersion: (version: string | null) => void; // Update prop type
    agentVersions: any[];
    onUpdatedAgentVersion: (updatedAgentVersion: any) => void;
    agentName?: string;
  },
  props: TableProps
) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [agentsTable, setAgentsTable] = useState(agentVersions ?? []);
  const { deleteAgentVersion, loading: deleteLoading } = useDeleteAgentVersion();
  const toast = useToast();

  useEffect(() => {
    setAgentsTable(agentVersions);
  }, [agentVersions]);

  const handleEdit = (version: string) => {
    setSelectedVersion(version); // Use setSelectedVersion from props
    setIsEditing(true);
  };

  const handleDelete = async (version: string) => {
    const success = await deleteAgentVersion(id, version);
    if (success) {
      toast({
        title: "Version deleted.",
        description: `Version has been deleted successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setAgentsTable((prev) => prev.filter((v) => v.id !== version));
    }
    setIsEditing(false);
    setSelectedVersion(null);
    onClose();
  };

  const handleOpenLink = (label: string, url: string) => {
    window.open(url, "_blank");
  };

  if (!agentsTable || agentsTable.length === 0) {
    return (
      <Center height="50vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading versions for agent</Text>
      </Center>
    );
  }

  return (
    <>
      {isEditing && selectedVersion ? (
        <VersionConfig
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          agentId={id}
          version_id={selectedVersion}
          onUpdatedAgentVersion={onUpdatedAgentVersion}
        />
      ) : (
        <Table {...props} minW="50vw">
          <Thead>
            <Tr>
              <Th>Number</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Created</Th>
              <Th>Updated</Th>
              <Th>Default</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {agentsTable.map((source) => (
              <Tr key={source.id}>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.version}
                  </Text>
                </Td>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.name}
                  </Text>
                </Td>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.description}
                  </Text>
                </Td>
                <Td>
                  <Text color="fg.muted">
                    {new Date(source.inserted_at).toLocaleDateString()}
                  </Text>
                </Td>
                <Td>
                  <Text color="fg.muted">
                    {new Date(source.updated_at).toLocaleDateString()}
                  </Text>
                </Td>
                <Td>
                  <Badge
                    size="sm"
                    colorScheme={source.is_default ? "green" : "ghost"}
                  >
                    {source.is_default ? "Yes" : ""}
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing="1">
                    <Button
                      variant="link"
                      color="purple.500"
                      onClick={() => handleEdit(source.id)}
                    >
                      <EditIcon />
                    </Button>
                    <Tooltip label={`Open version`}>
                      <IconButton
                        icon={<LinkIcon />}
                        variant="tertiary"
                        color="purple.500"
                        aria-label="Open version"
                        onClick={() => {
                          const version_link=`${BOT_API_URL}/${id}/${source.id}#token=${loadToken()}`;
                          handleOpenLink(source.name, version_link);
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      label={
                        source.is_default
                          ? "Cannot delete default version"
                          : "Delete"
                      }
                    >
                      <IconButton
                        icon={<FiTrash2 />}
                        variant="tertiary"
                        aria-label="Delete version"
                        onClick={() => {
                          setSelectedVersion(source.id);
                          onOpen();
                        }}
                        isDisabled={source.is_default}
                      />
                    </Tooltip>
                  </HStack>
                  <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete Version
                        </AlertDialogHeader>
                        <AlertDialogBody>
                          Are you sure you want to delete this version? This
                          action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button
                            ref={cancelRef}
                            onClick={onClose}
                            isDisabled={deleteLoading}
                            variant="outline"
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={() => handleDelete(selectedVersion!)}
                            ml={3}
                            isDisabled={deleteLoading}
                          >
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};
