import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bg="gray.50"
      px={4}
    >
      <Heading size="2xl" color="red.500" mb={4}>
        404
      </Heading>
      <Text fontSize="lg" color="gray.600" mb={6}>
        Oops! The page you're looking for doesn't exist.
      </Text>
      <Button colorScheme="blue" size="lg" onClick={() => navigate("/home")}>
        Go to Home
      </Button>
    </Box>
  );
};
