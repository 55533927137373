import { Flex } from "@chakra-ui/react";
import { AgentsDashboard } from "../components/MyAgents/AgentsDashboard";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";
export const Home = () => {
  const { agents, loading } = useListAgents();

  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar agents={agents} agentsLoading={loading}/>
      </Flex>
      <Flex flex="4" justify="center" direction="column" align="center">
        <AgentsDashboard />
      </Flex>
    </Flex>
  );
};
