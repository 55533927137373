import { Flex } from "@chakra-ui/react";
import { ProfileSettings } from "../components/Profile/ProfileSettings";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";

export const Settings = () => {
  const { agents } = useListAgents();useListAgents();
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar agents={agents} />
      </Flex>
      <Flex flex="4" justify="center">
        <ProfileSettings />
      </Flex>
    </Flex>
  );
};
